<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right;">
        <el-input placeholder="行业名称" v-model="searchForm.industry_name" size="small" class="input-with-select"
          style="width:200px;margin-left: 10px" clearable></el-input>

        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
          @click="getList">搜索</el-button>

        <el-button size="small" icon="el-icon-plus" @click="formAdd" type="primary">新增</el-button>
        <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" style="margin-left: 10px;" size="small"
          type="primary" @click="tongbuJava">迁移老版本</el-button>
      </div>
      <div style="padding-top: 14px;">
        <el-breadcrumb>
          <el-breadcrumb-item>字典管理</el-breadcrumb-item>
          <el-breadcrumb-item>行业类型</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
       <el-table v-tableFit :data="tableData" height="calc( 100% - 0px )" style="width: 100%;" row-key="id" border stripe
        default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column type="index" label="序号" width="80" align="center" />
        <el-table-column prop="industry_name" label="名称" min-width="100" />
        <el-table-column prop="sort" label="排序" width="100" align="center" />
        <el-table-column prop="create_name" label="创建人" width="100" align="center" />
        <el-table-column prop="create_date" label="创建时间" width="200" align="center" />
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-popconfirm width="250" confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red"
              @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
              <el-tag class="pointer" slot="reference" size="mini" type="danger">删除</el-tag>

            </el-popconfirm>
            <el-tag class="pointer ml10" type="primary" size="mini" @click="handleModify(scope.row)">修改</el-tag>
            <el-tag class="pointer ml10" type="success" size="mini" v-if="scope.row.level != 3"
              @click="handleAdd(scope.row)">添加下级</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true">
      <div class style="padding: 0 0 40px 40px;color:#f56c6c;">*添加行业信息请维护两级结构（例如 第一级：广告/媒体，第二级：印刷/包装/造纸）</div>
      <el-form :model="formData" :rules="rules" ref="adminUserInfo" label-width="120px"
        style="max-height: 450px;overflow-y: auto;min-height: 300px;">
        <el-form-item v-if="!(formData.id && formData.parent_id == 0)" label="上级行业" prop="parent_id">
          <el-select v-model="formData.parent_id" style="width: 50%">
            <el-option label="根级" :value="0"></el-option>
            <el-option v-for="(item, index) in tableData" :label="item.industry_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业名称" prop="industry_name">
          <el-input v-model.trim="formData.industry_name" style="width: 50%" />
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)" controls-position="right"
            style="width: 50%" />
        </el-form-item>
      </el-form>
      <el-row>
        <el-col :span="22" style="text-align: right;">
          <el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
          <el-button size="small" type="primary" @click="submitForm('adminUserInfo')">保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/api";

export default {
  name: "Menu",
  data() {
    return {
      searchForm: { industry_name: "" },
      isShowModal: false,
      modalTitle: "添加",
      formData: {},
      tableData: [],
      rules: {
        industry_name: [
          {
            required: true,
            message: "请输入",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let _this = this;
      this.$http
        .post("/api/zp_industry_tree", {
          industry_name: this.searchForm.industry_name,
          pmid: window.pmid
        })
        .then(res => {
          if (res.data.tree && res.data.tree.length > 0) {
            _this.deleteEmptyGroup(res.data.tree).then(result => {
              _this.tableData = result;
            });
          } else {
            _this.tableData = [];
          }
        });
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item["children"]) {
                if (item["children"].length > 0) {
                  traversal(item["children"]);
                }
                if (item["children"].length == 0) {
                  delete item["children"];
                }
              }
            }
          });
        }

        traversal(treeData);
        resolve(treeData);
      });
    },

    formAdd() {
      this.isShowModal = true;
      this.formData = {};
      this.formData = {
        parent_id: 0,
        parent_ids: 0,
        sort: 1
      };
      this.modalTitle = "添加";
    },
    handleAdd(row) {
      this.isShowModal = true;
      this.formData = {};
      this.formData = {};
      this.modalTitle = "添加";
    },
    handleModify(row) {
      this.isShowModal = true;
      this.modalTitle = "修改";
      this.formData = row;
    },
    handleDel(row) {
      this.$http
        .post("/api/zp_industry_delete", {
          id: row.id
        })
        .then(res => {
          this.$message.success("删除成功");
          this.getList();
        });
    },
    submitForm(formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          this.formData.parent_ids = this.formData.parent_id;
          this.$http.post("/api/zp_industry_edit", this.formData).then(res => {
            this.$message.success("保存成功");
            this.getList();
            this.isShowModal = false;
          });
        }
      });
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_zp_industry", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    }
  }
};
</script>
<style scoped lang="less" type="text/less">
.Menu {
  height: 100%;

  .el-row,
  .el-row .el-col {
    height: 100%;
  }

  .el-tabs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .el-tabs__content {
    flex: 1;

    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
